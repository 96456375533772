<template>
  <div class="bbb message">
    <van-nav-bar title="尚云方-最近药店" left-arrow @click-left="onClickLeft" />
    <div class="page-wrapper">
      <div>
        <div class="margin_top bpd" v-for="(item, index) of to_pharmacy_list" :key="index">
          <div class="title display jcsb aic">
            <p class="title_name">
              <span class="title_icon"></span>
              <span>{{ item.pharmacy_name }}</span>
            </p>
            <p>
              <van-button
                v-if="item.stateName == '待支付'"
                color="#1557FF"
                size="small"
                @click="YBPayImg(item)"
                class="message_btn"
              >请支付</van-button>
              <van-button
                v-else-if="item.stateName == '待申请'"
                color="#1557FF"
                size="small"
                @click="created_order(item)"
                class="message_btn"
              >申请问诊</van-button>
              <van-button
                v-else-if="item.stateName == '已完成'"
                color="#1557FF"
                size="small"
                @click="openPres(item)"
                class="message_btn"
              >下载</van-button>
              <van-button
                v-else-if="item.ih_type == 'NewYXHospital' && item.pay_state == '3'"
                color="#1557FF"
                size="small"
                @click="chat(item)"
                class="message_btn"
              >请和医生沟通</van-button>
              <van-button
                v-else-if="item.ih_type != 'NewYXHospital' && (item.pay_state == '1' || item.pay_state == '2' || item.pay_state == '3')"
                color="#1557FF"
                size="small"
                @click="chat(item)"
                class="message_btn"
              >请和医生沟通</van-button>
            </p>
          </div>
          <div class="margin_top padding_lr_4_1">
            <span class="Medication_title">地址：</span>
            <span class="Medication ">{{ item.address }}</span>
            <!-- <span class="Medication_title">距离：</span>
            <span class="Medication color_red">{{ item.distance }}米</span> -->
          </div>
          <div class="margin_top padding_lr_4_1 display jcsb padding_r_0">
            <div>
              <span class="Medication Medication_time ">
                <!-- color_blue -->
                步行距离：
                <span class="color_red">{{ Math.ceil(item.path.route.paths[0].distance) }}</span>
                米
                预计：
                <span class="color_blue">{{ Math.ceil(item.path.route.paths[0].duration / 60) }}</span>
                分钟
            </span>
            </div>
          </div>
          <div class="margin_top padding_lr_4_1 display jce padding_r_0">
            <span class="message_state mr_10">
              <van-button
                color="linear-gradient(to right, #1557FF, #1557FF)"
                size="small"
                class="message_btn"
                icon="sign"
                @click="go_pharmacy(item)"
              >去药店</van-button>
            </span>
            <span class="message_state">
              <van-button
                color="linear-gradient(to right, #1557FF, #1557FF)"
                size="small"
                icon="testsheet"
                class="message_btn"
                @click="select_pharmacy(item)"
              >先开方,后取药</van-button>
            </span>
          </div>
        </div>

        <van-nodata
          v-if="to_pharmacy_list.length === 0"
          :figure="imgUrl[0]"
          title="仅显示最近7天可退款问诊"
        />
        <div v-if="to_pharmacy_list.length <= 1" class="qr-wrap">
          <img src="../assets/image/QRcode.jpg" alt="" />
          <p style="text-align: center; padding: 0 10px; font-weight: 600">
            请保存截屏后长按该图片关注公众号，以更快获得问诊进展
          </p>
        </div>
      </div>
    </div>

    <iframe v-if="url !== '' && is_open_iframe" class="iframe" :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
import method from '../assets/js/util.js';
import mixin from '@/utils/mixin';

export default {
  mixins: [mixin],
  components: {
  },
  data() {
    return {
      to_pharmacy_list: [],
      url: '',
      is_open_iframe: false,
    };
  },
  methods: {
    go_pharmacy(item) {
      this.url = `https://uri.amap.com/marker?position=${item.location}`
      this.is_open_iframe = true
    },
    select_pharmacy(item) {
      this.$router.push({
        path: '/',
        query: {
          pharmacy_id: item.pharmacy_id,
        },
      });
    },
    onClickLeft() {
      if(this.is_open_iframe){
        this.is_open_iframe = false
        return
      }
      this.$router.back(-1);
    },
    loadList() {
      this.$api.article.near_pharmacy().then((res) => {
        console.log(res.data.result);
        this.to_pharmacy_list = res.data.result;
      });
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="less" scoped>
.iframe{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.mr_10{
    margin-right: 10px;
}
.color_red{
    color: red !important;
}
.color_blue{
    color: blue !important;
}
.van-nav-bar__title {
  color: #111 !important;
  font-weight: 600 !important;
}

.bbb {
  min-height: 100vh;
  background: linear-gradient(to bottom, #eef4fd, #f6f6f6);
}

.page-wrapper {
  padding: 0 0.6rem 4rem;
}

.title_name {
  font-family: SourceHanSansCN;
  font-weight: bold;
  font-size: 0.94rem;
  color: #282828;

  b {
    color: #1557ff;
  }
}

.title_icon {
  margin-right: 0.41rem;
  display: inline-block;
  width: 0.19rem;
  height: 0.81rem;
  background: #1557ff;
  border-radius: 0.09rem;
}

.Medication_title {
  font-family: SourceHanSansCN;
  font-weight: 500;
  font-size: 0.88rem;
  color: #282828;
}

.Medication {
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 0.94rem;
  color: #282828;
  line-height: 1.31rem;
}

.qr-wrap {
  margin-top: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  img {
    width: 7.56rem;
    height: 7.56rem;
  }

  p {
    margin-top: 1.69rem;
    width: 15.31rem;
    font-family: SourceHanSansCN;
    font-weight: 500;
    font-size: 0.94rem;
    color: #9a9a9a;
    line-height: 1.5rem;
  }
}

.bpd {
  padding: 0.97rem 0.91rem 1.19rem;
  background: #ffffff;
  border-radius: 0.69rem;
}

.van-nodata {
  top: 4vh;
  transform: none;
}

.padding_lr_4_1 {
  padding-left: 0.41rem;
  padding-right: 0.41rem;
}
.jcsb{
    justify-content: space-between;
}
.jce{
    justify-content: end;
}
</style>